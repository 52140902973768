import React, { useRef, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Typography, Link, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Section, SubSection, SubSubSection } from "./components/Sections";
import { DescriptionButton } from "./components/Buttons";
import { Strong } from "./components/Utilies";
import { subscribe_link } from "./Data";
import YouTube from 'react-youtube';
import { Title } from "./components/Titles";
import { HashLink } from "react-router-hash-link";

export default function Landing(props) {
    const theme = useTheme();
    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(ref.current.offsetWidth)
    }, []);

    const videoWidth = Math.min(width, 700)
    const opts = {
        height: videoWidth / 1920 * 1080,
        width: videoWidth,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <Box ref={ref} margin={theme.spacing(0, 0, 8)}>
            <Box margin={theme.spacing(8, "auto", 1)}>
                <img src="logo-color.png" style={{width: "40%", textAlign: "left"}} />
            </Box>
            <Box margin={theme.spacing(1, "auto", 6)}>
                <Typography
                    variant={
                        useMediaQuery(theme.breakpoints.up("sm")) ? "h4" : "h5"
                    }
                    color="textPrimary"
                >
                    <strong>M</strong>ulti<strong>L</strong>ingual <strong>S</strong>peech processing <strong>U</strong>
                    niversal <strong>PER</strong>formance <strong>B</strong>
                    enchmark
                </Typography>
            </Box>
            <Box margin={theme.spacing(1, "auto", 6)}>
                <p><strong><a href={subscribe_link} target="_blank" rel="noopener noreferrer">Subscribe</a></strong> our e-news to receive all the latest information about SUPERB or <strong>contact us</strong> via</p>
                <p><strong><a href="mailto:superb.announcement@gmail.com" target="_blank">superb.announcement@gmail.com</a></strong></p>
            </Box>
            <Box maxWidth={800} margin={theme.spacing(1, "auto", 6)}>
                <Typography variant="h6" color="textPrimary">
                    <strong>ASRU 2023  ML-SUPERB Challenge Timeline</strong>
                </Typography>
                <span align="left">
                <HashLink to="/challenge-asru2023/challenge_overview#top">Challenge Policy</HashLink>&nbsp;&nbsp;&nbsp;
                <ul>
                    <li>
                        <span><Strong>May 12, 2023</Strong>: Challenge announcement</span>
                    </li>
                    <li><Strong>May 19, 2023</Strong>: Leaderboard
                        <span> is online and accepting submissions</span>
                    </li>
                    <li><span><Strong>June 26, 2023</Strong>: </span><span> New Language Track Submission Deadline</span></li>
                    <li><span><Strong>July 07, 2023</Strong>: </span><span> Paper / Model Submission Deadline</span></li>
                    <li><span><Strong>July 10, 2023</Strong>: </span><span> Paper Revision Deadline</span></li>
                </ul>
            </span>
            </Box>
            <Box maxWidth={800} margin="auto" textAlign="left">
                <SubSection>
                    <SubSubSection>
                        <Typography
                            component={"span"}
                            variant="body1"
                            color="textSecondary"
                        >
                            Multilingual SUPERB (ML-SUPERB) is an extension of the <a href={"https://superbbenchmark.org/"} target="_blank" rel="noopener noreferrer">SUPERB benchmark</a>, 
                            designed to evaluate the cross-lingual capabilities of speech representation learning. ML-SUPERB consists of three distinct tracks:
                            
                            <div
                                style={{
                                    width: "fit-content",
                                    margin: "auto",
                                    textAlign: "left",
                                }}
                            >
                                <ol>
                                    <li>
                                        <Strong>Evaluation</Strong>: A benchmark of cross-lingual speech processing tasks, considering performance in both monolingual and multilingual scenarios. 
                                    </li>
                                    <li>
                                    <Strong>New Language</Strong>: An open invitation to extend the benchmark to new languages. 
                                    </li>
                                    <li>
                                    <Strong>Research Paper</Strong>: For research papers that utilize the ML-SUPERB evaluation framework. 
                                    </li>
                                </ol>
                            </div>
                            The broader goal of ML-SUPERB is to extend the coverage of Self-Supervised Learning (SSL) to languages across the globe.
                            The <Strong>monolingual</Strong> evaluation track tests the generalization ability of SSL models to new languages in a few-shot setting.
                            In the <Strong>multilingual</Strong> evaluation, models are simultaneously benchmarked on 143 languages, ranging from high-resource to endangered. 
                        </Typography>
                    </SubSubSection>
                    <SubSubSection>
                        <Typography variant="body1" color="textSecondary">
                            We are excited to introduce a special <Strong>New Language Track</Strong> for ASR researchers interested in exploring different languages.
                            This track aims to encourage researchers to submit their own languages that align with the languages in ML-SUPERB,
                            thereby broadening multilingual research to encompass more languages from around the world.  
                            The submitted data will be used as an open evaluation set for other participants who have submitted their SSL models to the challenge.
                        </Typography>
                    </SubSubSection>
                    <SubSubSection>
                        <Typography variant="body1" color="textSecondary">
                            Further details about the overall SUPERB project can be found <a href={"https://superbbenchmark.org/"} target="_blank" rel="noopener noreferrer">here</a>.
                        </Typography>
                    </SubSubSection>
                </SubSection>
            </Box>
            <Box maxWidth={800} margin="auto" textAlign="left">
                <SubSection>
                    <Grid container justify="space-evenly" spacing={0}>
                        {[
                            ["ntu-1000.png", "https://www.ntu.edu.tw/english/"],
                            ["cmu-1000.png", "https://www.cmu.edu/"],
                            ["fair-1000.png", "https://ai.facebook.com/"],
                            ["rembrand-1000.png", "https://www.rembrand.com/"],
                        ].map((filename) => {
                            return (
                                <Grid item xs={6} sm={4} md={6} key={filename[0]}>
                                    <a target="_blank" href={filename[1]}>
                                        <img src={filename[0]} width="100%" />
                                    </a>
                                </Grid>
                            );
                        })}
                    </Grid>
                </SubSection>
                <Box margin={theme.spacing(8, 0)} textAlign="center">
                    <Title title="Acknowledgement"/>
                    <Typography variant="body1" color="textSecondary">
                        We thank <DescriptionButton name={<a>Shu-wen Yang</a>} link="https://github.com/leo19941227" /> and <DescriptionButton name={<a>Ming-Yang Ho</a>} link="https://kaminyou.com/" /> for creating and maintaining the SUPERB official website.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
